<template>
  <div
    class="stripe"
    :class="{
      flipped: flipped,
      'full-width': fullWidth,
      bottom: bottom,
      'in-product-section': inProductSection,
    }"
  ></div>
</template>

<script>
export default {
  name: "Stripe",
  props: {
    fullWidth: {
      type: Boolean,
      default: false,
    },
    bottom: {
      type: Boolean,
      default: false,
    },
    flipped: Boolean,
    inProductSection: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.stripe {
  background-color: #cf012c;
  height: 5px;
  width: 60%;
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: left;
  &.flipped {
    right: 0;
    left: unset;
    transform-origin: right;
  }
  &.full-width {
    width: 100%;
  }
  &.bottom {
    bottom: 0;
    top: unset;
  }
  &.in-product-section {
    left: -15px;
    right: unset;
    &.flipped {
      right: -15px;
      left: unset;
    }
  }
}
</style>
