<template>
  <div class="home">
    <ProductSection
      v-for="(product, index) in productsList"
      :key="`product-${index}`"
      :animKey="`product-${index}`"
      :product-name="product.name"
      :product-image-filename="product.imageFilename"
      :product-brand-logo-filename="product.brandLogoFilename"
      :product-year="product.year"
      :product-award-logo-filenames="product.awardLogoFilenames"
      :flipped="index % 2 !== 0"
      :product-image-height="product.height"
    ></ProductSection>
  </div>
</template>

<script>
// @ is an alias to /src
import ProductSection from "@/components/ProductSection";
import ScrollReveal from "scrollreveal";

export default {
  name: "Home",
  components: {
    ProductSection,
  },
  mounted() {
    // window.addEventListener("load", () => {
    //   ScrollReveal({ reset: false }).reveal(".product-section", {
    //     duration: 750,
    //     viewFactor: 0.4,
    //   });
    // });
  },

  data: function () {
    return {
      productsList: [
        {
          name: "Grundig Artemis New Hair Care Family",
          year: 2021,
          brandLogoFilename: "grundig.png",
          imageFilename: "Grundig NaturaShine serisi.jpg",
          awardLogoFilenames: ["reddot.png"],
          height: "1080",
        },
        {
          name: "Grundig Band Digital Radio",
          year: 2021,
          brandLogoFilename: "grundig.png",
          imageFilename: "Grundig Band Dijital Radyo.jpg",
          awardLogoFilenames: ["reddot.png"],
          height: "1440",
        },
        {
          name: "Grundig Club Bluetooth Speaker",
          year: 2021,
          brandLogoFilename: "grundig.png",
          imageFilename: "Grundig Club Bluetooth Hoparlör.jpg",
          awardLogoFilenames: ["if.png", "reddot.png"],
          height: "1357",
        },
        {
          name: "Grundig GR 7700 Dryer",
          year: 2021,
          brandLogoFilename: "grundig.png",
          imageFilename: "Grundig GR 7700 Kurutma Makinası.jpg",
          awardLogoFilenames: ["reddot.png"],
          height: "1440",
        },
        {
          name: "Grundig GR 7700 Washing Machine",
          year: 2021,
          brandLogoFilename: "grundig.png",
          imageFilename: "Grundig Gr 7700 Çamaşır Makinası.jpg",
          awardLogoFilenames: ["reddot.png"],
          height: "1440",
        },
        {
          name: "Arçelik Ultrahijyen Series Refrigerator",
          year: 2021,
          brandLogoFilename: "arcelik.png",
          imageFilename: "Arçelik UltraHijyen Serisi Buzdolabı.jpg",
          awardLogoFilenames: ["reddot.png"],
          height: "1080",
        },
        {
          name: "Inova D83 Freezer Top Platform",
          year: 2021,
          brandLogoFilename: "grundig.png",
          imageFilename: "INOVA D83 FREEZER TOP PLATFORM.jpg",
          awardLogoFilenames: ["if.png", "reddot.png"],
          height: "1280",
        },
        {
          name: "Inova K78 Freezer Bottom Platform",
          year: 2021,
          brandLogoFilename: "grundig.png",
          imageFilename: "INOVA K78 FREEZER BOTTOM PLATFORM.jpg",
          awardLogoFilenames: ["if.png", "reddot.png"],
          height: "1440",
        },
        {
          name: "Beko UV Filter Accessory for Air Conditioners",
          year: 2021,
          brandLogoFilename: "beko.png",
          imageFilename: "Beko UV Hijyen Klima Aksesuarı.jpg",
          awardLogoFilenames: ["reddot.png"],
          height: "1440",
        },
        {
          name: "Arçelik Automated Guided Vehicle",
          year: 2021,
          brandLogoFilename: "arcelik.png",
          imageFilename: "Arcelik_Automated_Guided_Vehicle_.jpg",
          awardLogoFilenames: ["if.png", "reddot.png"],
          height: "1440",
        },
        {
          name: "Heinzelmann Radio Packaging",
          year: 2021,
          brandLogoFilename: "grundig.png",
          imageFilename: "Heizelmann Radyo Ambalajı.jpg",
          awardLogoFilenames: ["reddot.png"],
          height: "1080",
        },
        {
          name: "Grundig GR 7700 Built-in Oven",
          year: 2021,
          brandLogoFilename: "grundig.png",
          imageFilename: "Grundig_GR7700_Built_in_Oven.jpg",
          awardLogoFilenames: ["if.png"],
          height: "1440",
        },
        {
          name: "Grundig GR 7700 Dishwasher",
          year: 2021,
          brandLogoFilename: "grundig.png",
          imageFilename: "Grundig_GR7700_Dishwasher.jpg",
          awardLogoFilenames: ["if.png"],
          height: "1440",
        },
        {
          name: "Inova D78 Freezer Top Platform",
          year: 2021,
          brandLogoFilename: "grundig.png",
          imageFilename: "INOVA D78 FREEZER TOP PLATFORM.jpg",
          awardLogoFilenames: ["if.png"],
          height: "1280",
        },
        {
          name: "Voltas Beko Direct Cool Single Door Refrigerator",
          year: 2021,
          brandLogoFilename: "voltas-beko.png",
          imageFilename: "Voltas_beko_direct_cool_single_door_refrigerator.jpg",
          awardLogoFilenames: ["if.png"],
          height: "1440",
        },
        {
          name: "Arçelik Automated Tea Maker",
          year: 2021,
          brandLogoFilename: "arcelik.png",
          imageFilename: "Arçelik_Automatic_Tea_maker.jpg",
          awardLogoFilenames: ["if.png"],
          height: "1440",
        },
        {
          name: "Arçelik Inox Samovar",
          year: 2021,
          brandLogoFilename: "arcelik.png",
          imageFilename: "Arçelik_Inox_Samovar.jpg",
          awardLogoFilenames: ["if.png"],
          height: "1440",
        },
        {
          name: "Beko 60cm Refrigerator",
          year: 2021,
          brandLogoFilename: "beko.png",
          imageFilename: "Beko_60cm_Refrigerator.jpg",
          awardLogoFilenames: ["if.png"],
          height: "1440",
        },
        {
          name: "Beko Beyond Series bPro500 Dishwasher",
          year: 2021,
          brandLogoFilename: "beko.png",
          imageFilename: "Beko_Beyond_Series_bPro500_Dishwasher.jpg",
          awardLogoFilenames: ["if.png"],
          height: "1440",
        },
        {
          name: "Beko Beyond Series bPro500 Built-in Oven",
          year: 2021,
          brandLogoFilename: "beko.png",
          imageFilename: "Beko_bPro_500_built-in_Oven.jpg",
          awardLogoFilenames: ["if.png"],
          height: "1153",
        },
        {
          name: "Beko Beyond Series bPro700 Built-in Oven",
          year: 2021,
          brandLogoFilename: "beko.png",
          imageFilename: "Beko_bPro_700_Built-in_Oven.jpg",
          awardLogoFilenames: ["if.png"],
          height: "1153",
        },
        {
          name: "Beko Mini Air Purifier",
          year: 2021,
          brandLogoFilename: "beko.png",
          imageFilename: "Beko_mini_air_purifier.jpg",
          awardLogoFilenames: ["if.png"],
          height: "1440",
        },
        {
          name: "Grundig GR 7700 Refrigerator",
          year: 2021,
          brandLogoFilename: "grundig.png",
          imageFilename: "Grundig_GR_7700_Refrigerator.jpg",
          awardLogoFilenames: ["if.png"],
          height: "1281",
        },
        {
          name: "Grundig GR 9900 Built-in Hob",
          year: 2021,
          brandLogoFilename: "grundig.png",
          imageFilename: "Grundig_GR_9900_Built_in_Hob.jpg",
          awardLogoFilenames: ["if.png"],
          height: "1440",
        },
        {
          name: "Grundig GR 9900 Built-in Oven",
          year: 2021,
          brandLogoFilename: "grundig.png",
          imageFilename: "Grundig_GR_9900_Built_in_Oven.jpg",
          awardLogoFilenames: ["if.png"],
          height: "1440",
        },
        {
          name: "Beko PowerClean Stick Süpürge",
          year: 2020,
          brandLogoFilename: "beko.png",
          imageFilename: "2.2.jpg",
          awardLogoFilenames: ["if.png", "reddot.png"],
          height: "1920",
        },
      ],
    };
  },
};
</script>
