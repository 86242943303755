<template>
  <div id="app">
    <div class="intro-section bg-tile bg-white">
      <Stripe class="anime-load-stripe-scale" full-width></Stripe>
      <HeaderFooter
        class="anime-load-stagger"
        :logo-set="['arcelik-red.png', 'logo2-black.png']"
      ></HeaderFooter>
      <b-container fluid>
        <b-row>
          <b-col cols="12">
            <div class="intro-text anime-load-stagger">
              Take A Closer Look At Our Award&#8209;Winning Design Journey
            </div>
          </b-col>
        </b-row>
      </b-container>

      <div class="bg-tp-black">
        <div class="video-container anime-load-stagger">
          <video
            class="intro-video"
            data-poster="/videos/video-poster.jpg"
            playsinline
            controls
            style="
              --plyr-color-main: gray;
              --plyr-control-padding: 10px;
              --plyr-video-control-color: white;
            "
            preload="metadata"
          ></video>
        </div>
      </div>

      <div class="position-relative">
        <Stripe class="anime-load-stripe-scale"></Stripe>

        <b-container fluid>
          <div class="d-flex justify-content-center anime-load-stagger">
            <img class="award-logo" src="/award-logos/if.png" alt="" />
            <img class="award-logo" src="/award-logos/reddot.png" alt="" />
          </div>
        </b-container>
      </div>
      <div class="d-flex justify-content-end">
        <img class="year-stamp" src="/images/2021.png" alt="" />
      </div>
    </div>
    <router-view />
    <HeaderFooter
      :bg-color="'#CF012C'"
      :logo-set="['arcelik-white.png', 'logo2-white.png']"
    ></HeaderFooter>
  </div>
</template>

<style lang="scss">
@import "node_modules/bootstrap/scss/bootstrap.scss";
@import "node_modules/bootstrap-vue/src/index.scss";

#app {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  background-color: #f5f5f5;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.video-container {
  max-width: 720px;
  margin: auto;
}

.intro-section {
  .award-logo {
    height: 16em;
    margin: 4em 6em;
  }
  .intro-text {
    font-size: 5.7em;
    font-weight: 200;
    text-align: center;
    margin-bottom: 1em;
    padding: 0em 1em;
  }

  .year-stamp {
    height: 4.5em;
    margin-bottom: 2em;
  }
}
@include media-breakpoint-down(md) {
  .intro-section {
    .award-logo {
      height: 11em;
      margin: 4em 4em;
    }
    .intro-text {
      font-size: 2.5em;
    }
  }
}
</style>
<script>
import HeaderFooter from "@/components/HeaderFooter";
import Stripe from "@/components/Stripe";
import Plyr from "plyr";
import anime from "animejs";
import videojs from "video.js";
export default {
  components: { Stripe, HeaderFooter },
  mounted() {
    let timeline = anime.timeline({
      duration: 1000,
      delay: 50,
      easing: "easeOutQuart",
    });
    timeline.add({
      targets: ".anime-load-stripe-scale",
      scaleX: [0, 1],
      easing: "easeOutQuart",
    });

    timeline.add({
      targets: ".anime-load-stagger",
      translateY: [-20, 0],
      opacity: [0, 1],
      delay: anime.stagger(300),
      easing: "easeOutQuart",
    });

    const player = new Plyr("video", {});
    player.source = {
      type: "video",
      title: "Example title",
      sources: [
        {
          src: "/videos/arcelik-IF-RedDotDesign-r1.mp4",
          type: "video/mp4",
          size: 720,
        },
      ],
    };
  },
};
</script>
<style>
.anime-load-stagger {
  opacity: 0;
}
.anime-load-stripe-scale {
  transform: scaleX(0);
}
</style>
