<template>
  <div class="product-section" :id="animKey">
    <b-container fluid>
      <b-row
        class="product-info-image-wrapper"
        :class="{ 'flex-row-reverse': flipped }"
      >
        <b-col
          cols="12"
          lg="5"
          order="2"
          order-lg="1"
          class="bsrap-column-info"
        >
          <div class="product-info-section">
            <Stripe :flipped="flipped" in-product-section></Stripe>
            <img
              class="brand-logo"
              :src="`/brand-logos/${productBrandLogoFilename}`"
              alt=""
            />
            <hr style="width: 100%" />

            <div class="product-name">{{ productName }}</div>
            <div class="year">{{ productYear }}</div>
            <div class="awards-wrapper">
              <img
                class="award-logo"
                :key="`${productName}-award-${awardLogoFilename}`"
                v-for="awardLogoFilename in productAwardLogoFilenames"
                :src="`/award-logos/${awardLogoFilename}`"
                alt=""
              />
            </div>
          </div>
        </b-col>
        <b-col
          cols="12"
          lg="7"
          order="1"
          order-lg="2"
          class="bsrap-column-image"
        >
          <div
            class="product-image-section"
            :style="{ paddingBottom: `${(productImageHeight / 1920) * 100}%` }"
          >
            <img
              v-lazy="`/product-images/${productImageFilename}`"
              class="product-image"
              id="testid"
              alt=""
            />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Stripe from "@/components/Stripe";
import anime from "animejs";
export default {
  name: "ProductSection",
  components: { Stripe },
  props: {
    productName: String,
    productImageFilename: String,
    productBrandLogoFilename: String,
    productAwardLogoFilenames: Array,
    productYear: Number,
    flipped: { type: Boolean, default: false },
    animKey: String,
    productImageHeight: String,
  },
  methods: {
    doAnim: function (_animKey) {
      let productBox = document.querySelector(`#${_animKey}`);
      let productImage = document.querySelector(`#${_animKey} .product-image`);
      let productStripe = document.querySelector(`#${_animKey} .stripe`);

      let productBrandLogo = document.querySelector(`#${_animKey} .brand-logo`);
      let productName = document.querySelector(`#${_animKey} .product-name`);
      let year = document.querySelector(`#${_animKey} .year`);
      let awardsWrapper = document.querySelectorAll(
        `#${_animKey} .awards-wrapper`
      );
      let timeline = anime.timeline({
        duration: 1000,
        easing: "easeOutQuart",
      });
      timeline.add({
        targets: productBox,
        opacity: [0, 1],
      });
      timeline.add(
        {
          targets: productStripe,
          scaleX: [0, 1],
        },
        100
      );

      if (this.flipped) {
        timeline.add(
          {
            targets: productImage,
            opacity: [0, 1],
            translateX: [-50, 0],
          },
          200
        );
      } else {
        timeline.add(
          {
            targets: productImage,
            opacity: [0, 1],
            translateX: [50, 0],
          },
          200
        );
      }

      timeline.add(
        {
          targets: productBrandLogo,
          opacity: [0, 1],
        },
        600
      );
      timeline.add(
        {
          targets: productName,
          opacity: [0, 1],
          translateY: [-5, 0],
        },
        "-=400"
      );
      timeline.add(
        {
          targets: year,
          opacity: [0, 1],
          translateY: [-20, 0],
        },
        "-=800"
      );
      timeline.add(
        {
          targets: awardsWrapper,
          opacity: [0, 1],
          translateY: [-20, 0],
        },
        "-=800"
      );
    },
  },
  mounted() {
    window.mobileCheck = function () {
      let visitorIsMobile = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        )
          visitorIsMobile = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return visitorIsMobile;
    };
    let observerOptions;
    if (window.mobileCheck()) {
      observerOptions = {
        // root: document.querySelector(`#${this.animKey}`),
        root: null,
        rootMargin: "10px",
        threshold: 0.25,
      };
    } else {
      observerOptions = {
        // root: document.querySelector(`#${this.animKey}`),
        root: null,
        rootMargin: "10px",
        threshold: 0.4,
      };
    }

    let observer = new IntersectionObserver((_e) => {
      if (_e[0].isIntersecting) {
        this.doAnim(_e[0].target.id);
        observer.disconnect();
      }
    }, observerOptions);

    let target = document.querySelector(`#${this.animKey}`);
    observer.observe(target);
  },
};
</script>

<style scoped lang="scss">
@import "node_modules/bootstrap/scss/bootstrap.scss";
@import "node_modules/bootstrap-vue/src/index.scss";

.product-section {
  margin-bottom: 4em;
  background-color: white;
  opacity: 0;
}
.product-sections-wrapper {
}
.product-info-section {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 4em 0em;
  hr {
    margin-top: 1em;
    margin-bottom: 1em;
  }
  .brand-logo {
    object-fit: contain;
    transform: scale(0.5);
    transform-origin: left;
  }

  .awards-wrapper {
    display: flex;

    .award-logo {
      height: 7em;
      object-fit: contain;
      margin-right: 2em;
    }
  }
  .product-name {
    font-weight: 300;
    font-size: 2.7em;
  }
  .year {
    color: #8e8e8e;
    font-weight: 700;
    font-size: 2.2em;
    margin: 0.5em 0em;
  }
}
.product-image-section {
  height: 100%;
  width: 100%;
  position: relative;
  img {
    width: 100%;
    object-fit: cover;
    height: 100%;
    position: absolute;
  }
}

.bsrap-column-image {
  padding-right: 0;
  padding-left: 15px;
}
.flex-row-reverse {
  .bsrap-column-image {
    padding-right: 15px;
    padding-left: 0;
  }
}

@include media-breakpoint-down(md) {
  .product-section {
    .flex-row-reverse {
      flex-direction: unset;
    }
  }

  .product-info-section {
    padding: 2em 0;
    hr {
      margin-top: 0.5em;
      margin-bottom: 0.5em;
    }
    .brand-logo {
      transform: scale(0.35);
      transform-origin: left;
    }

    .product-name {
      font-size: 2.3em;
    }
    .year {
      margin: 0.5em 0;
      font-size: 2em;
    }
  }

  .bsrap-column-image {
    padding-right: 0;
    padding-left: 0px;
  }
  .flex-row-reverse {
    .bsrap-column-image {
      padding-right: 0px;
      padding-left: 0;
    }
  }
}
</style>
