<template>
  <div class="header-footer" :style="{ backgroundColor: bgColor }">
    <template v-for="(logo, index) in logoSet">
      <div class="d-flex" :key="`headerlogoseparator-${logo}`" v-if="index > 0">
        <div class="vertical-separator"></div>
      </div>
      <img alt="" :key="`headerlogo-${logo}`" :src="`/brand-logos/${logo}`" />
    </template>
  </div>
</template>

<script>
export default {
  name: "Header",
  props: {
    bgColor: {
      type: String,
      default: "none",
    },
    logoSet: Array,
  },
};
</script>

<style scoped lang="scss">
@import "node_modules/bootstrap/scss/bootstrap.scss";
@import "node_modules/bootstrap-vue/src/index.scss";

.header-footer {
  padding: 3em 0em;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: stretch;
  img {
    height: 2.85em;
    margin: 0em 3em;
  }
  .vertical-separator {
    border-left: 1px solid #cbcbcb;
    position: relative;
    top: -25%;
    height: 150%;
  }
}

@include media-breakpoint-down(md) {
  .header-footer {
    img {
      height: 2.6em;
    }
  }
}
</style>
