import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./custom.scss";

import { BootstrapVue } from "bootstrap-vue";
import VueLazyload from "vue-lazyload";
// Import Bootstrap an BootstrapVue CSS files (order is important)

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin

Vue.use(VueLazyload);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
